'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.incidents.factory:IncidentPriorityDispatchUnitTypesManager

 # @description

###
angular
  .module 'mundoAdmin.incidents'
  .factory 'IncidentPriorityDispatchUnitTypesManager',  (
  $mdDialog
  $log
  RestUtils
  DispatchUnitTypesManager
  
  MundoDefaultManager)->
    IncidentPriorityDispatchUnitTypesManagerBase = new MundoDefaultManager()
    IncidentPriorityDispatchUnitTypesManagerBase.setUrl('dispatch_unit_types')
    IncidentPriorityDispatchUnitTypesManagerBase.setAutoTenant()
    IncidentPriorityDispatchUnitTypesManagerBase.setNewObject(['dispatchUnitType', 'minimalAmount'])
    IncidentPriorityDispatchUnitTypesManagerBase.setParentUrl('lpa/incidents/priorities')

    IncidentPriorityDispatchUnitTypesManagerBase.getNewForm = ->
      [
        key: 'dispatchUnitType'
        name: 'Dispatch unit type'
        type: 'select'
        templateOptions:
          label: 'Dispatch unit type'
          placeholder: 'Dispatch unit type'
          required: true
          labelProp: "label"
          valueProp: "id"
          options: DispatchUnitTypesManager.getFullList({'sort' : 'label,ASC'}).$object
      ,
        key: 'minimalAmount'
        name: 'minimalAmount'
        type: 'input'
        templateOptions:
          label: 'Minimal Amount'
          placeholder: 'Minimal amount'
          required: true
      ]

    IncidentPriorityDispatchUnitTypesManagerBase.getEditForm = (data) ->
      [
        key: 'dispatchUnitType'
        name: 'Dispatch unit type'
        type: 'select'
        defaultValue: if data.dispatchUnitType then data.dispatchUnitType.id else ''
        templateOptions:
          label: 'Dispatch unit type'
          placeholder: 'Dispatch unit type'
          required: true
          labelProp: "label"
          valueProp: "id"
          options: DispatchUnitTypesManager.getFullList({'sort' : 'label,ASC'}).$object
      ,
        key: 'minimalAmount'
        name: 'minimalAmount'
        type: 'input'
        defaultValue: data.minimalAmount
        templateOptions:
          label: 'Minimal Amount'
          placeholder: 'Minimal amount'
          required: true
      ]

    IncidentPriorityDispatchUnitTypesManagerBase
